import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();


class TokenService {
  getLocalRefreshToken() {
    const user = cookies.get("user");
    return user ? user.refresh_token : null
  }

  getLocalAccessToken() {
    const user = cookies.get("user");
    return user ? user.access_token : null
  }

  updateLocalAccessToken(token) {
    let user = cookies.get("user");
    user.access_token = token;
    // prevent save access_token or refresh_token
    // user.access_token = ''
    // end
    cookies.set("user", user);
  }

  getUser() {
    return cookies.get("user");
  }

  setUser(user) {
    // prevent save access_token or refresh_token
    // user.access_token = ''
    // user.refresh_token = ''
    // end
    cookies.set("user", user);
  }

  removeUser() {
    cookies.remove("user");
  }

  setStatus(status) {
    cookies.set('status', status);
  }

  getStatus() {
    return cookies.get("status");
  }
  removeAll() {
    cookies.keys().forEach(cookie => cookies.remove(cookie))
  }
}

export default new TokenService();
