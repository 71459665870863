<template>
  <router-view />
</template>
<script>
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { AUTH_LOGOUT } from "@/store/actions/auth";

export default {
  created: () => {
    const router = useRouter();
    const store = useStore();

    axios.interceptors.response.use(
      (response) => {
        if (response.status === 401) {
          alert("You are not authorized");
        }
        return response;
      },
      (error) => {
        if (
          error.response.status === 401 &&
          error.config &&
          (!error.config.__isRetryRequest ||
            error.config.__isRetryRequest === undefined)
        ) {
          // if you ever get an unauthorized, logout the user
          store.dispatch(AUTH_LOGOUT);
          // you can also redirect to /login if needed !
          router.push({ name: "Login" });
        }

        if (error.response && error.response.data) {
          return Promise.reject(error.response.data);
        }
        // return Promise.reject(error.message);
        return error.message;
      }
    );
  },
};
</script>

<style>
</style>
