import { createStore } from 'vuex';
import user from "./modules/user";
import auth from "./modules/auth";

const debug = process.env.NODE_ENV !== 'production';

const store = createStore({
  state: {
    modules: [],
    compareItems: [],
  },
  getters: {
    getModules: (state) => {
      return state.modules
    },
    getCompareItems: (state) => {
      return state.compareItems
    }
  },
  mutations: {
    setModules(state, payload) {
      state.modules = (payload.modules)
    },
    addCompareItem(state, payload) {
      state.compareItems.push(payload.data)
    },
    removeCompareItem(state, payload) {
      state.compareItems.splice(payload.index, 1)
    },
  },
  actions: {
    setModules(context, payload) {
      context.commit('setModules', payload)
    },
    addCompareItem(context, payload) {
      if (context.state.compareItems.length >= 2) {
        context.commit('removeCompareItem', { index: 0 })
      }
      context.commit('addCompareItem', payload)
    },
    removeCompareItem(context, payload) {
      context.commit('removeCompareItem', payload)
    }
  },
  modules: {
    user, auth
  },
  strict: debug
});

export default store
