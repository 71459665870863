import { createRouter, createWebHistory } from 'vue-router'
import TokenService from "@/services/token.service";

function loadView(view) {
    return () => import(`../views/${view}.vue`);
}

function loadComponent(view) {
    return () => import(`../components${view}`);
}


const routes = [
    {
        path: '/login',
        name: 'Login',
        components: { default: loadView('Login') },
    },

    {
        path: "/",
        // redirect: "/home",
        component: loadView('Layout'),
        children: [
            {
                path: '/websites',
                name: 'Websites',
                components: { default: loadView('Home') },
            },
            {
                path: "/websites/page/:page",
                name: "WebsitesPage",
                components: { default: loadView('Home') },
                props: true
              },
            {
                path: '/',
                name: 'Alerts',
                components: { default: loadView('Alerts') },
            },
            {
                path: '/diff/:sourceId/:destId',
                name: 'Diff',
                components: { default: loadView('Diff') },
                props: true
            },
            {
                path: "/websites/:id",
                name: "WebsiteDetail",
                components: { default: loadComponent('/website/Detail.vue') },
                props: true
            },
            {
                path: "/websites/:id/task/:task_id",
                name: "WebsiteTaskDetail",
                components: { default: loadComponent('/website/Detail.vue') },
                props: true
            },
        ]
    },
    {
        // will match everything
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: loadView('NotFound'),
    }
]

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    const publicPages = ['/login', '/signup'];
    const authRequired = !publicPages.includes(to.path);
    const status = TokenService.getStatus()
    const loggedIn = status ? status['loggedIn'] : false

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (loggedIn && to.path == '/login') {
        next('/')
    } else if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router
