import axios from "axios";

axios.defaults.xsrfCookieName = 'webping_csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true

const instance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API + '/api/v1/',
  headers: {
    "Content-Type": "application/json",
  },

  credentials: "include",
  timeout: 30000,
  timeoutErrorMessage: 'Timeout'
});

export default instance;