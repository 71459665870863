import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.css"
import 'bootstrap-icons/font/bootstrap-icons.css'
import "bootstrap"

// global styles
import './assets/main.css'

// import store
import store from './store/index'

// store & api config
import setupInterceptors from './services/setupInterceptors';

setupInterceptors(store)

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')
